import { BG_COLORS } from '@constants';
import { ctaType, imageType } from '@lib/proptypes';
import Carousel from '@molecules/Carousel/Carousel';
import ImageSliderItem from '@molecules/ImageSliderItem/ImageSliderItem';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import PropTypes from 'prop-types';

function ImageSliderSection({
  title,
  text,
  items,
  cta,
  backgroundColor,
  displayScrollBar,
  id,
}) {
  if (!items?.length) return null;

  return (
    <PagepartBase id={id} backgroundColor={backgroundColor.color}>
      <Carousel
        title={title}
        text={text}
        items={items}
        cta={cta}
        SlideItem={ImageSliderItem}
        displayScrollBar={displayScrollBar}
      />
    </PagepartBase>
  );
}

ImageSliderSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  cta: ctaType,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      ...imageType,
    })
  ),
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
  displayScrollBar: PropTypes.bool,
};

export default ImageSliderSection;
