import BlobImage from '@atoms/BlobImage/BlobImage';
import Figure from '@atoms/Figure/Figure';
import { BG_COLORS, BG_WHITE } from '@constants';
import { ctaType } from '@lib/proptypes';
import TextMediaWrapper from '@molecules/TextMediaWrapper/TextMediaWrapper';
import PropTypes from 'prop-types';

function TextImageSection({
  id,
  title,
  subtitle,
  text,
  cta,
  ctaIcon,
  image,
  imageAlt,
  showImageAsBlob,
  textLeft,
  backgroundColor = { color: BG_WHITE },
  children,
}) {
  return (
    <TextMediaWrapper
      id={id}
      title={title}
      subtitle={subtitle}
      text={text}
      cta={cta}
      ctaIcon={ctaIcon}
      textLeft={textLeft}
      backgroundColor={backgroundColor.color}
      content={children}
    >
      {showImageAsBlob ? (
        <BlobImage
          image={image}
          alt={imageAlt}
          sizes="(min-width: 1024px) 599px, (min-width: 1280px) 624px, (min-width: 1536px) 684px, 100vw"
        />
      ) : (
        <Figure
          image={image}
          alt={imageAlt}
          sizes="(min-width: 1024px) 599px, (min-width: 1280px) 624px, (min-width: 1536px) 684px, 100vw"
        />
      )}
    </TextMediaWrapper>
  );
}

TextImageSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  cta: ctaType,
  ctaIcon: PropTypes.func,
  textLeft: PropTypes.bool,
  showImageAsBlob: PropTypes.bool,
  image: PropTypes.shape({
    url: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  imageAlt: PropTypes.string,
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }),
  children: PropTypes.node,
};

export default TextImageSection;
