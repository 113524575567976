import { BG_COLORS, BG_WHITE } from '@constants';
import { ctaType } from '@lib/proptypes';
import JobAlertCard from '@molecules/JobAlertCard/JobAlertCard';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import PropTypes from 'prop-types';

function ContactSection({
  title,
  text,
  phone,
  email,
  cta,
  backgroundColor,
  id,
}) {
  const modifier = backgroundColor.color === BG_WHITE && 'alternativeShadow';

  return (
    <PagepartBase backgroundColor={backgroundColor.color} id={id}>
      <JobAlertCard
        title={title}
        text={text}
        phone={phone}
        email={email}
        modifier={modifier}
        firstCta={cta}
      />
    </PagepartBase>
  );
}

ContactSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  phone: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      phone: PropTypes.string,
      isWhatsapp: PropTypes.bool,
    })
  ),
  email: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  cta: ctaType,
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
};

export default ContactSection;
