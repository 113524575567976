import { BG_COLORS } from '@constants';
import { eventPageOverviewType } from '@lib/proptypes';
import Carousel from '@molecules/Carousel/Carousel';
import EventItem from '@molecules/EventItem/EventItem';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import useEventsSection from '@utils/useEventsSection';
import useStore from '@utils/useStore';
import PropTypes from 'prop-types';

function EventsSection({
  title,
  events,
  locations,
  highlightedOnly,
  backgroundColor,
  id,
}) {
  const {
    store: { page },
  } = useStore();

  const { relatedEvents } = useEventsSection({
    events,
    locations,
    highlightedOnly,
  });

  const cta = {
    title: 'Bekijk alle evenementen',
    internalLink: [
      {
        slug: `/agenda${
          page.typename === 'LocationPage' ? `?locations=${page.id}` : ''
        }`,
      },
    ],
  };

  return (
    <PagepartBase id={id} backgroundColor={backgroundColor.color}>
      <Carousel
        title={title}
        items={relatedEvents}
        cta={cta}
        SlideItem={EventItem}
      />
    </PagepartBase>
  );
}

EventsSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  events: PropTypes.arrayOf(eventPageOverviewType),
  locations: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  highlightedOnly: PropTypes.bool,
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
};

export default EventsSection;
