import { BG_COLORS } from '@constants';
import { ctaType } from '@lib/proptypes';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import Image from 'next/image';
import PropTypes from 'prop-types';

import styles from './ImageSection.module.scss';

function ImageSection({
  id,
  title,
  text,
  cta,
  image,
  imageAlt,
  backgroundColor,
}) {
  const hasContent = title || text || cta?.title;

  return (
    <PagepartBase
      id={id}
      title={title}
      text={text}
      cta={cta}
      backgroundColor={backgroundColor.color}
      extraClasses={hasContent && styles.hasContent}
    >
      <div className={styles.imageContainer}>
        <Image
          src={image.url}
          placeholder="blur"
          blurDataURL={image.placeholder}
          width={100}
          height={100}
          alt={imageAlt}
          className={styles.image}
          sizes="(min-width: 1536px) 1520px, 100vw"
        />
      </div>
    </PagepartBase>
  );
}

ImageSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  cta: ctaType,
  image: PropTypes.shape({
    url: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  imageAlt: PropTypes.string,
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
};

export default ImageSection;
