import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import { Widget } from '@typeform/embed-react';
import PropTypes from 'prop-types';

import styles from './TypeformSection.module.scss';

export default function TypeformSection({ typeform, id }) {
  const typeformId = typeform[0].id;

  return (
    <PagepartBase id={id}>
      <Widget id={typeformId} className={styles.widget} />
    </PagepartBase>
  );
}

TypeformSection.propTypes = {
  id: PropTypes.string,
  typeform: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};
